import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://foodinnovation16-0ef187785ef0.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://foodinnovation16-0ef187785ef0.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (

    <>

<section className="testimonial  pt-140 pb-140 container" id="testimonial-sec">
  {
    testimonialtext.map(a=> <div className="sec-title sec-title--marketing text-center mb-60">
      <span className="subtitle">{a.testimonialText}</span>
      <h2 className="title">{a.testimonialHeading}</h2>
    </div> )
  }
  
  <div className="mr-testimonial-slider swiper-container">
    <div className="d-flex col-lg-4">

      {
        testimonial.map(s=> <div className="swiper-slide mr-testimonial container">
          <div className="xb-item--inner">
            <div className="xb-item--quote">
              <img src="assets/img/icon/mr-quote.png" alt />
            </div>
            <div className="xb-item--content mb-90">
            {s.desc}
            </div>
            <div className="xb-item--author ul_li">
              <div className="xb-item--avatar">
                <img src={s.image} alt />
              </div>
              <div className="xb-item--author-info">
                <h3 className="xb-item--name">{s.personName} </h3>
                <span className="xb-item--desig">{s.personTitle}</span>
              </div>
            </div>
          </div>
        </div>)
      }

      
     
      
    </div>
  </div>
</section>



    </>



  );
};

export default TestimonialSection;
