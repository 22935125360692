import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PricingSection.css';


const PricingSection = () => {

  const [pricing, setPricing] = useState([]);


  useEffect(() => {
    fetch(`https://foodinnovation16-0ef187785ef0.herokuapp.com/pricings`)
      .then((res) => res.json())
      .then((info) => setPricing(info));
  }, []);

  return (




<>

<section className="pricing pos-rel z-1 pt-140 pb-150" data-bg-color="#E7E9EE" id='pricing-sec'>
  <div className="container">
    <div className="sec-title text-center mb-60">
    
      <h2 className="title">Flexible pricing <span>Plan</span></h2>
      
    </div>
    
    <div className="tab-content" id="pills-tabContent">
      <div className="tab-pane show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
        <div className="row justify-content-md-center mt-none-30">

        {
              pricing.map(b => b.packageName === 'Basic' && <div className="col-lg-4 col-md-6 mt-30">
                <div className="xb-pricing">
                  <span className="xb-item--title">{b.packageName} Plan</span>
                  <p className="xb-item--subtitle">Most Popular</p>
                  <h2 className="xb-item--price text-black">${b.packagePrice} <span></span></h2>
                  <ul className="xb-item--list list-unstyled mb-35">
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointOne}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointTwo}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointThree}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointFour}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointFive}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{b.packagePointSix}</li>
                    
                  </ul>
                 
                  <Link to={`/order-now/${b._id}`} className="xb-item--btn xb-btn">
                     {b.packageButtonText}  <i className="far fa-long-arrow-right" />
                   </Link>
                  <div className="xb-item--icon">
                    <img src="assets/img/icon/price_icon.png" alt />
                  </div>
                </div>
              </div>)}

              {
              pricing.map(s => s.packageName === 'Premium' && <div className="col-lg-4 col-md-6 mt-30">
                <div className="xb-pricing primary">
                  <span className="xb-item--title">{s.packageName} Plan</span>
                  <p className="xb-item--subtitle">Recommended</p>
                  <h2 className="xb-item--price text-black">${s.packagePrice}<span></span></h2>
                  <ul className="xb-item--list list-unstyled mb-35">
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointOne}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointTwo}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointThree}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointFour}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointFive}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointSix}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{s.packagePointSeven}</li>
                   
                  </ul>
                

                  <Link to={`/order-now/${s._id}`} className="xb-item--btn xb-btn">
                   {s.packageButtonText}  <i className="far fa-long-arrow-right" />
                     </Link>
                  <div className="xb-item--icon">
                    <img src="assets/img/icon/price_icon.png" alt />
                  </div>
                </div>
              </div>)}

              {
              pricing.map(p => p.packageName === 'Standard' && <div className="col-lg-4 col-md-6 mt-30">
                <div className="xb-pricing">
                  <span className="xb-item--title">{p.packageName} Plan</span>
                  <p className="xb-item--subtitle">Best value</p>
                  <h2 className="xb-item--price text-black">${p.packagePrice} <span></span></h2>
                  <ul className="xb-item--list list-unstyled mb-35">
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointOne}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointTwo}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointThree}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointFour}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointFive}</li>
                    <li><span><img src="assets/img/icon/check.svg" alt /></span>{p.packagePointSix}</li>
                   
                  </ul>
                 
                  <Link to={`/order-now/${p._id}`} className="xb-item--btn xb-btn">
                      {p.packageButtonText} <i className="far fa-long-arrow-right" />
                    </Link>
                  <div className="xb-item--icon">
                    <img src="assets/img/icon/price_icon.png" alt />
                  </div>
                </div>
              </div> )}

          

          

          

        </div>
      </div>
      <div className="tab-pane" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
      
      </div>
    </div>
  </div>
  
</section>




</>
  );
};

export default PricingSection;